<template>

  <div>

    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>
    </template>

    <template v-else >


      <!-- modo busqueda-->
      <template v-if="searchMode">
        <b-card
            no-body
            class="faq-search"
        >
          <b-card-body class="text-center">

            <h2 class="text-primary mb-2">
              Consultar perfil del colaborador
            </h2>

            <v-select
                v-model="selectedEmployee"
                label="fullName"
                :options="employeesList"
                :reduce="option => option.empId"
                @input="employeeSearch"
            />

          </b-card-body>

        </b-card>
      </template>

      <!-- modo resultados-->
      <template v-else>
        <b-card>
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <feather-icon
                icon="UserIcon"
                size="19"
            />
            <h4 class="mb-2 ml-50">
              Información Personal
            </h4>
          </div>

          <b-row>
            <b-col>
              <b-form-group
                  label="Nombres completos"
                  label-for="employeeFullName"
              >
                <b-form-input
                    id="employeeFullName"
                    :value="fullName"
                    readonly
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                  label="Oficina"
                  label-for="employeeOffice"
              >
                <b-form-input
                    id="employeeOffice"
                    :value="employee.offNombre"
                    readonly
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                  label="Area"
                  label-for="employeeSection"
              >
                <b-form-input
                    id="employeeSection"
                    :value="employee.secNombre"
                    readonly
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                  label="Correo electrónico"
                  label-for="employeeEmail"
              >
                <b-form-input
                    id="employeeEmail"
                    :value="employee.empEmail"
                    readonly
                />
              </b-form-group>
            </b-col>
          </b-row>


          <template v-if="hasTaskManagement">

            <!-- Header: Personal Info -->
            <div class="d-flex mt-2">
              <feather-icon
                  icon="TrelloIcon"
                  size="19"
              />
              <h4 class="mb-2 ml-50">
                Gestión de Roles
              </h4>
            </div>

            <b-list-group>
              <b-list-group-item
                  class="flex-column align-items-start"
                  v-for="(task, index) in tasks"
                  :key="index"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">
                    {{ getTaskName(task) }}
                  </h5>
                  <small class="text-secondary">{{ getFormatoFecha(task.created_at)}}</small>
                </div>
                <b-card-text class="mb-1">
                  <!-- modo roles sistema -->
                  <template v-if="task.roles.length > 0">
                    <ul>
                      <li v-for="(role, index) in task.roles" :key="index">
                        <span class="text-capitalize">{{ getToDoName(task.frsTarea) }}</span> - {{ role.rolNombre }}
                      </li>
                    </ul>
                  </template>
                  <!-- modo cambio de oficina -->
                  <template v-else>

                  </template>


                </b-card-text>
                <small class="text-secondary">{{ task.frsJustificacion }}</small>
              </b-list-group-item>

            </b-list-group>

          </template>

          <template v-else>

            <b-card class="text-center">

              <feather-icon
                  icon="InfoIcon"
                  size="100"
                  class="mr-50 mb-3 mt-2"
              />
              <h2>No hay registros de tareas</h2>
              <p class="p-2">
                No se encontraron registros de tareas en esta sección.
              </p>
            </b-card>

          </template>





          <!-- nueva busqueda -->
          <b-row class="mt-2">
            <b-col>
              <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="newSearch"
              >
                Nueva consulta
              </b-button>
            </b-col>
          </b-row>

        </b-card>
      </template>


    </template>







  </div>

</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BSpinner,
  BFormGroup,
  BButton, BListGroup, BListGroupItem, BCardText
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import { resolveFormatoFecha, resolveToDoName } from '@/modules/common/utils'

export default {
  name: 'Colaborador',
  components: {
    BButton,
    vSelect,
    BRow,
    BCol,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BMedia,
    BSpinner,
    BFormGroup, BListGroup, BListGroupItem, BCardText
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      searchMode: true,
      employeesList: [],
      selectedEmployee: null,
      employeeData: []
    }
  },

  computed: {

    employee() {
      return this.employeeData?.employee ?? []
    },

    fullName(){
      return `${this.employee?.empNombres} ${this.employee?.empApellidos}`
    },

    tasks() {
      return this.employeeData?.tasks ?? []
    },

    hasTaskManagement(){
      return this.employeeData.tasks.length > 0
    }



  },

  methods: {
    ...mapActions('security-module', ['getEmployeesList', 'getEmployeeStatus']),

    // cargar datos de empleados para consultar
    async loadData(){

      try {

        this.isLoading = true
        this.employeesList = await this.getEmployeesList()
        this.employeesList = this.employeesList.map(employee => {
          return {
            ...employee,
            fullName: `${employee.empNombres} ${employee.empApellidos}`
          };
        });

      } catch (e) {
        console.log("UNEXPECTED", e)
      } finally {
        this.isLoading = false
      }

    },

    //consultar datos de un empleado
    async employeeSearch(){

      try {

        this.isBusy = true

        this.employeeData = await this.getEmployeeStatus({ id: this.selectedEmployee })

        this.searchMode = false

      } catch (error) {

      } finally {
        this.isBusy = false
      }

    },

    //iniciar nueva consulta
    newSearch(){
      this.searchMode = true
      this.employeeData = []
      this.selectedEmployee = null
    },

    getFormatoFecha( date ){
      return resolveFormatoFecha( date )
    },

    getTaskName( task ){
      return task?.destino ? "Oficina" : task.sysNombre
    },

    getToDoName( taskId ){
      return resolveToDoName( taskId )
    }



  },
  async created() {
    await this.loadData()
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/pages/page-faq.scss';
</style>
